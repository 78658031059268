<template>
  <!-- :='scro' -->
  <div class="container">
    <van-tabs
      :ellipsis="false"
      v-model="index"
      scrollspy
      sticky
      title-active-color="#0266E6"
    >
      <van-tab
        class="tabs"
        v-for="(item, index) in port"
        :title="item.name"
        :key="index"
      >
        <div ref="tab" class="content">
          <div class="title" :class="{ tis: item.num }">
            <div class="t-name">{{ item.name }}</div>
            <div v-if="item.num" class="t-num">{{ item.num }}项</div>
            <div v-else class="t-wnum">0项</div>
          </div>
          <div v-if="item.num">
            <div
              class="box"
              v-for="(item, index) in item.children"
              :key="index"
            >
              <div class="children">
                <div>{{ index + 1 }}.{{ item.name }}</div>
                <div>{{ item.tip }}</div>
              </div>
              <div>
                <div class="ctitle">检查情况</div>
                <div v-for="(item, index) in item.contents" :key="index">
                  <div class="check-box">
                    <div class="cbox-titpe">
                      <div>情况明细{{ index + 1 }}</div>
                      <div>#{{ item.name }}</div>
                    </div>
                    <div class="cbox-content">{{ item.remarks }}</div>
                    <ul class="carI-list">
                      <li v-for="(ite, ind) in item.images" :key="ind">
                        <img
                          :src="ite"
                          alt=""
                          @click="onImageClick(item.images, ind)"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div>
                <div class="ctitle">整备情况</div>
                <div v-for="(item, index) in item.prepares" :key="index">
                  <div class="check-box">
                    <div class="cbox-titpe cbox-titpes">
                      <div>情况明细{{ index + 1 }}</div>
                      <div>#{{ item.level }}</div>
                    </div>
                    <div class="cbox-content">{{ item.remarks }}</div>
                    <ul class="carI-list">
                      <li v-for="(ite, ind) in item.images" :key="ind">
                        <van-image
                          fit="cover"
                          @click="onImageClick(item.images, ind)"
                          :src="ite"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <img
      class="back_img"
      @click="call_back"
      src="../../assets/backs.png"
      alt=""
    />
  </div>
</template>
  <script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { ImagePreview } from "vant";
import { getTechAppraisalPre } from "@/utils/http.js";
export default {
  name: "",
  components: {
    swiper,
    swiperSlide,
  },
  props: [],
  data() {
    return {
      scro: true,
      index: 0,
      startX: null,
      startXs: null,
      surrentStartX: null,
      surrentStartXs: null,
      nextPage: 1,
      swiperOption: {
        loop: false,
        slideToClickedSlide: true,
        // 显示分页
        pagination: {
          // el: ".swiper-pagination",
          // clickable: true //允许分页点击跳转
        },
      },
      show: false,
      current: 1,
      active: 1,
      port: [
        {
          name: "骨架检查项目",
          num: 2,
          children: [
            {
              index: 1,
              name: "前围骨架",
              tip: "维护",
              check: [
                {
                  title: "检查情况",
                  type: "漆面受损",
                  content: "前面置及左右包角漆面受损面积大于15cm",
                  img: [
                    "https://vehicle-library.oss-cn-beijing.aliyuncs.com/evaluation/image/42c4b8bc-9184-449c-8ea6-84426eb27bcf.jpg",
                    "https://vehicle-library.oss-cn-beijing.aliyuncs.com/evaluation/image/42c4b8bc-9184-449c-8ea6-84426eb27bcf.jpg",
                    "https://vehicle-library.oss-cn-beijing.aliyuncs.com/evaluation/image/42c4b8bc-9184-449c-8ea6-84426eb27bcf.jpg",
                    "https://vehicle-library.oss-cn-beijing.aliyuncs.com/evaluation/image/42c4b8bc-9184-449c-8ea6-84426eb27bcf.jpg",
                  ],
                },
              ],
              restore: [
                {
                  title: "整备情况",
                  type: "维护",
                  content: "对前面置及左右包角进行了维护",
                  img: [
                    "../../assets/zhengb.png",
                    "../../assets/zhengb.png",
                    "../../assets/zhengb.png",
                    "../../assets/zhengb.png",
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "驾驶室外观与内饰检查项目",
          num: 0,
          children: [],
        },
      ],
      list: "",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getReportInfo();
  },
  methods: {
    onImageClick(list, index) {
      console.log(list);
      let arr = [];
      list.forEach((item) => {
        arr.push(item.value);
      });
      console.log(list);
      ImagePreview({
        images: list,
        startPosition: index,
        showIndex: false,
      });
    },
    call_back() {
      this.$router.go(-1);
    },
    async getReportInfo() {
      const { data: res } = await getTechAppraisalPre({
        id: this.$route.query.ids,
      });
      res.content;
      console.log(res.content, "========");
      //   console.log(JSON.parse(res.content.technicalState));
      // res.content.technicalState = JSON.parse(res.content.technicalState);
      res.content.pop();
      res.content.forEach((item) => {
        if (item.children.length) {
          item.num = item.children.length;
        }
        item.children.forEach((ite) => {
          if (ite.prepares.length) {
            ite.tips = [];
            ite.prepares.forEach((it) => {
              ite.tips.push(`#${it.level}`);
              ite.tip = ite.tips.join("、");
            });
            console.log(ite, "===");
          }
        });
      });
      console.log(res.content, "=======111=");
      //   for (let i = 0; i < res.content.length; i++) {
      //     let children = res.content[i].children;
      //     let index = 1;
      //     for (let j = 0; j < children.length; j++) {
      //       children[j].index = index;
      //       index++;
      //     }
      //     if (i > 0) {
      //       let prevChildren = res.content[i - 1].children;
      //       let lastObj = prevChildren[prevChildren.length - 1];
      //       index = lastObj.index + 1;
      //       for (let j = 0; j < children.length; j++) {
      //         children[j].index = index;
      //         index++;
      //       }
      //     }
      //   }
      this.port = res.content;
    },
  },
};
</script>
  <style lang='less'  scoped>
.container {
  background: #f8f8f8;
  font-family: PingFangSC-Medium, PingFang SC;
  padding-bottom: 20px;
}

.content {
  width: 345px;
  margin: 0 auto;
  background: #ffffff;
  margin-top: 10px;
}
.title {
  width: 345px;
  height: 48px;
  background: #ffffff;
  border-radius: 8px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 12px;
  justify-content: space-between;
}
.tis {
  width: 345px;
  height: 48px;
  background: linear-gradient(180deg, #e7f0ff 0%, #ffffff 100%);
  border-radius: 7px 7px 0px 0px;
  border-radius: 8px;
}
.t-wnum {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #666666;
}

.t-num {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #00df47;
}
.box {
  box-sizing: border-box;
  padding: 0 12px;
}
.children {
  display: flex;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 15px;
  color: #333333;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #eeeeee;
}
.children > div:nth-of-type(2) {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.ctitle {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 15px;
  color: #333333;
  margin: 10px 0 4px 0;
}
.check-box {
  width: 321px;
//   height: 187px;
  background: #f8f8f8;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 12px 10px;
  margin-top: 3px;
  margin-bottom: 5px;
}
.cbox-titpe {
  display: flex;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 15px;
  color: #333333;
  align-items: center;
}
.cbox-titpe > div:nth-of-type(2) {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #f78d12;
  margin-left: 8px;
  line-height: 20px;
  background: rgba(255, 239, 213, 0.29);
  padding: 0 6px;
  border-radius: 3px;
  //   margin-top: 5px;
}
.cbox-titpes > div:nth-of-type(2) {
    background: #E5FBEC;
    color: #00DF47;
}
.cbox-content {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  margin: 7px 0 10px 0;
}
.carI-list {
//   height: 105px;
  display: flex;
  overflow-x: auto;
//   padding-bottom: 10px;
}

.carI-list::-webkit-scrollbar {
  display: none;
}

.carI-list > li {
  //   width: 142px;
  //   height: 106px;
  margin-right: 10px;
  position: relative;
}
// .carI-list > li:nth-of-type(1) {
//   margin-left: 12px;
// }

// .carI-list > li:last-child {
//   margin-right: 12px;
// }

.carI-list > li > .van-image {
  width: 112px;
  height: 84px;
  border-radius: 3px;
}
.carI-list img {
  width: 112px;
  height: 84px;
  border-radius: 3px;
}
/deep/ .carI-list > li > .van-image > img {
  border-radius: 3px;
}

/deep/ .van-tabs__line {
  width: 20px !important;
  height: 10px !important;
  background-color: transparent !important;
  background: url("https://ftp.bmp.ovh/imgs/2021/05/503330710675b702.png")
    center center;
  background-size: 100% 100%;
}

/deep/ .van-tab {
  font-size: 18px;
}

/deep/ .van-tabs--line .van-tabs__wrap {
  height: 50px;
}

/deep/ .center > .van-image {
  width: 345px;
  height: 259px;
  margin: 0 auto;
}

/deep/ .center > .van-image {
  width: 345px;
  height: 259px;
  border-radius: 5px;
}

/deep/ .van-popup__close-icon--top-right {
  top: 20px;
  right: 20px;
}

/deep/ .van-tab {
  font-size: 16px;
}

/deep/.van-tab--active > .van-tab__text {
  font-size: 19px;
}

.back_img {
  width: 40px;
  height: 40px;
  position: fixed;
  right: 10px;
  bottom: 150px;
  z-index: 99999999;
}
</style>